<template>
	<div class="row">
		<div class="form-group col-md-4">
			<label>Empresa</label>
			<v-select :options="requirements.enterprises" @input="mtd_changueEnterprise" label="denomination" placeholder="Seleccionar" />
		</div>
		<div class="form-group col-md-3">
			<label>Sedes</label>
			<v-select v-model="campu_id" :options="requirements.campus" :reduce="campu => campu.id" label="name" placeholder="Seleccionar" />
		</div>
		<div class="form-group col-md-2">
			<label>Estado</label>
			<v-select v-model="step_id" :options="requirements.steps" :reduce="step => step.id" label="name" placeholder="Seleccionar" />
		</div>
		<div class="form-group col-md-3">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_executeFilter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button @click="mtd_executeFilter" class="btn btn-xs btn-primary"><i class="fas fa-search"></i> Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			requirements: {
				enterprises: [],
				campus: [],
				steps: [],
			},
			enterprise_id: null,
			campu_id: null,
			step_id: null,
			text: null,
		};
	},
	created() {
		this.mtd_getRequirements();
		this.mtd_executeFilter();
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'execute_commit']),
		mtd_getRequirements() {
			this.get({ url: `${this.url_api}/requirements/index/filters` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.enterprises = res.enterprises;
						this.requirements.campus = res.campus;
						this.requirements.steps = res.steps;
					}
				})
				.catch(err => console.log(err));
		},
		mtd_changueEnterprise(enterprise) {
			this.campu_id = null;
			if (enterprise != null) {
				this.enterprise_id = enterprise.id;
				this.requirements.campus = enterprise.campus;
			} else {
				this.enterprise_id = null;
				this.requirements.campus = [];
			}
		},
		mtd_executeFilter(type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			this.get({
				url: `${this.url_api}/requirements/index?page=${pagination}&enterprise_id=${this.enterprise_id == null ? '' : this.enterprise_id}&campu_id=${
					this.campu_id == null ? '' : this.campu_id
				}&step_id=${this.step_id == null ? '' : this.step_id}&text=${this.text == null ? '' : this.text}`,
				mutation: 'general_administrator_requirements/REQUIREMENTS_INDEX__SET_ALL',
			});
		},
	},
};
</script>
